
  import { computed, defineComponent, onMounted, ref, watch } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import Multiselect from '@vueform/multiselect';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import Attachment from '@/components/common/Attachments.vue';
  import moment from 'moment';

  export default defineComponent({
    name: 'customer-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
      Attachment,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<number>(0);
      const selectedDoc = ref<string>('');
      const selectedNationality = ref<number>(0);
      const selectedGender = ref<number>(0);
      const selectedStatuses = ref<number>(0);
      const selectedCustomers = ref<number>(0);
      const selectedCategory = ref<number>(0);
      const selectedLevel = ref<number>(0);
      const selectedDayes = ref<Array<string>>([]);
      const dynamicGuardiansSchema = ref();
      const dateOfBirth = ref();
      const horseOwner = ref(false);
      const freeRegistration = ref(false);
      const tableData = ref([
        {
          name: '',
          email: '',
          phone: '',
          typeId: '',
        },
      ]);
      const primaryContactIndex = ref(0);
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const ageInYear = ref(0);

      watch(dateOfBirth, (value) => {
        calculateAge(value);
      });

      const calculateAge = (date) => {
        const currentDate = moment(date);
        const age = currentDate.diff(moment(), 'years');
        if (age > 0) {
          ageInYear.value = 0;
        } else {
          ageInYear.value = Math.abs(age);
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('customerCreating'), [
          translate('operations'),
        ]);
      });

      const validationSchema = Yup.object().shape({
        name: Yup.string()
          .required(() => translate('NAME_IS_REQUIRED_FIELD'))
          .label('Name'),
        email: Yup.string().email('Invalid email format').notRequired(),
        nationalityId: Yup.string()
          .notOneOf(['0'], () => translate('NATIONALITY_IS_REQUIRED_FIELD'))
          .required(() => translate('NATIONALITY_IS_REQUIRED_FIELD'))
          .label('nationalityId'),
        genderId: Yup.string()
          .notOneOf(['0'], () => translate('GENDER_IS_REQUIRED_FIELD'))
          .required(() => translate('GENDER_IS_REQUIRED_FIELD'))
          .label('genderId'),
        statusId: Yup.string()
          .notOneOf(['0'], () => translate('STATUS_IS_REQUIRED_FIELD'))
          .required(() => translate('STATUS_IS_REQUIRED_FIELD'))
          .label('Statuses'),
        civilId: Yup.string()
          .matches(/^\d{12}$/, {
            message: () => translate('Civil id must be 12 digits'),
          })
          .required(() => translate('CIVIL_IS_REQUIRED_FIELD'))
          .label('civil'),
        passportNumber: Yup.string()
          .required(() => translate('PASSPORT_IS_REQUIRED_FIELD'))
          .label('passport'),
        address: Yup.string()
          .required(() => translate('ADDRESS_IS_REQUIRED_FIELD'))
          .label('address'),
        typeId: Yup.string()
          .notOneOf(['0'], () => translate('CUSTOMER_TYPE_IS_REQUIRED_FIELD'))
          .required(() => translate('CUSTOMER_TYPE_IS_REQUIRED_FIELD'))
          .label('class category'),
        dateOfBirth: Yup.string()
          .required(() => translate('DATE_OF_BIRTH_IS_REQUIRED_FIELD'))
          .label('Date Of Birth'),
        classCategoryId: Yup.string()
          .notOneOf(['0'], () => translate('CATEGORY_IS_REQUIRED_FIELD'))
          .required(() => translate('CATEGORY_IS_REQUIRED_FIELD'))
          .label('category'),
        classLevelId: Yup.string()
          .notOneOf(['0'], () => translate('Class Level is required field'))
          .required(() => translate('Class Level is required field'))
          .label('arena'),
        contactList: Yup.array().of(
          Yup.object().shape({
            phone: Yup.string()
              .matches(/^\d{8}$/, {
                message: () => translate('Phone number is not valid'),
              })
              .required(() => translate('PHONE_NUMBER_IS_REQUIRED_FIELD')),
          })
        ),
      });

      const guardianValidation = computed(() => {
        return Yup.object().shape({
          ...dynamicGuardiansSchema.value,
        });
      });
      watch(
        tableData.value,
        async () => {
          dynamicGuardiansSchema.value = {};
          tableData.value.forEach((v, index) => {
            dynamicGuardiansSchema.value[`phone_${index}`] = Yup.string()
              .test('required', 'Phone number is required', function (value) {
                if (!value || value.trim() === '') {
                  return this.createError({
                    message: translate('PHONE_NUMBER_IS_REQUIRED_FIELD'),
                  });
                }
                return true;
              })
              .test(
                'valid-phone',
                'Phone number is not valid',
                function (value) {
                  if (value && !/^\d{8}$/.test(value)) {
                    return this.createError({
                      message: translate('Phone number is not valid'),
                    });
                  }
                  return true;
                }
              )
              .test(
                'unique-phone',
                'Phone number must be unique',
                function (value) {
                  const phoneCount = tableData.value.reduce((count, item) => {
                    if (item.phone === value) {
                      return count + 1;
                    }
                    return count;
                  }, 0);

                  const isPhoneExist = phoneCount > 1;

                  if (isPhoneExist && value !== '') {
                    return false;
                  }

                  return true;
                }
              );

            dynamicGuardiansSchema.value[`typeId_${index}`] =
              Yup.string().required(() =>
                translate('GUARDIAN_TYPE_IS_REQUIRED_FIELD')
              );
            dynamicGuardiansSchema.value[`email_${index}`] = Yup.string()
              .required(() => translate('EMAIL_IS_REQUIRED_FIELD'))
              .test('unique-email', 'Email must be unique', function (value) {
                const emailCount = tableData.value.reduce((count, item) => {
                  if (item.email === value) {
                    return count + 1;
                  }
                  return count;
                }, 0);

                const isEmailExist = emailCount > 1;

                if (isEmailExist && value !== '') {
                  return false;
                }

                return true;
              })
              .test('valid-email', 'Invalid email format', function (value) {
                return Yup.string().email().isValidSync(value);
              });

            dynamicGuardiansSchema.value[`name_${index}`] = Yup.string()
              .required(() => translate('NAME_IS_REQUIRED_FIELD'))
              .label('Full Name');
          });
        },
        { immediate: true }
      );
      const matchedClassLevelList = ref();

      watch(selectedCategory, async () => {
        const categoryId = selectedCategory.value;

        selectedLevel.value = 0;

        const categoryObj = categoryList.find((category) => {
          return category.id === categoryId;
        });
        matchedClassLevelList.value = classLevelList.filter((classLevel) =>
          categoryObj.classLevelId?.includes(classLevel.id)
        );
      });

      const customerId = ref('');

      const attachmentSchema = Yup.object().shape({
        documenttypeId: Yup.string()
          .required(() => translate('DOCUMENT_IS_REQUIRED_FIELD'))
          .label('Doc'),
      });

      const tableHeader = ref([
        {
          name: translate('name'),
          key: 'name',
          sortable: true,
        },
        {
          name: translate('email'),
          key: 'email',
          sortable: true,
        },
        {
          name: translate('phone'),
          key: 'phone',
          sortable: true,
        },
      ]);

      const onSubmitCreate = async (values) => {
        if (!isInternationalPhoneValid.value.every((value) => value)) {
          return;
        }
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        if (values.residencyNumber === '') {
          delete values.residencyNumber;
        }
        values.freeRegistration = freeRegistration.value;
        values.horseOwner = horseOwner.value;

        const payload = {
          ...values,
          contact: contactList.value.map((contact, index) => {
            const phoneNumber =
              contact.phone.length > 8 ? contact.phone : '965' + contact.phone;
            return {
              phone: phoneNumber,
              isPrimary: index === primaryContactIndex.value,
            };
          }),
        };
        values.passportNumber = String(values.passportNumber);

        const customer = await store.dispatch(Actions.CREATE_CUSTOMER, payload);
        if (customer) {
          customerId.value = customer.data.id;
        }

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_CUSTOMER'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        }
      };

      const onSubmitCreateGuardian = async () => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        const payload = {
          guardians: tableData.value.map((obj) => ({
            ...obj,
            phone: `965${obj.phone}`,
          })),
        };

        await store.dispatch(Actions.UPDATE_CUSTOMER, {
          data: payload,
          id: customerId.value,
        });

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];
        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_CUSTOMER'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        }
      };

      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.GENDER,
        LookupNameIds.NATIONALITY,
        LookupNameIds.STATUS,
        LookupNameIds.CLASS_TYPE,
        LookupNameIds.HORSE_TYPE,
        LookupNameIds.CUSTOMER_TYPE,
        LookupNameIds.DOCUMENT_TYPE,
        LookupNameIds.DAYS,
        LookupNameIds.GUARDIAN_TYPE,
      ]);

      const categoryList = await store.dispatch(
        Actions.GET_ALL_SETTINGS_CLASS_CATEGORY
      );
      const classLevelList = await store.dispatch(
        Actions.GET_ALL_SETTINGS_CLASS_LEVEL
      );

      const lookupsList = computed(() => store.getters.allLookupsList);

      const genders = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.GENDER;
      });

      const guardians = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.GUARDIAN_TYPE;
      });

      const nationalities = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.NATIONALITY;
      });

      const statuses = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.STATUS;
      });

      const documents = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.DOCUMENT_TYPE;
      });

      const classes = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.CLASS_TYPE;
      });

      const horses = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.HORSE_TYPE;
      });

      const customers = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.CUSTOMER_TYPE;
      });
      const preferredDayes = lookupsList.value
        .filter((lookup) => {
          return lookup.lookupNameId === LookupNameIds.DAYS;
        })
        .map((lookup) => {
          return { title: lookup.nameEn, id: lookup.id };
        });

      await store.dispatch(Actions.GET_ALL_SETTINGS_CUSTOMER_AGE);

      const onSubmitCreateAttachment = async (values, cb) => {
        await store.dispatch(Actions.CREATE_CUSTOMER_ATTACHMENT, {
          data: values,
          id: customerId.value,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_ATTACHMENT'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({
              name: 'customer-editing',
              params: { id: customerId.value },
            });
          });
        }
        cb();
      };

      const deleteCustomer = (index) => {
        tableData.value.splice(index, 1);
      };

      const addNewRow = () => {
        tableData.value.push({
          name: '',
          email: '',
          phone: '',
          typeId: '',
        });
      };

      const removeContact = (index) => {
        contactList.value.splice(index, 1);
      };

      const contactList = ref([
        {
          phone: '',
          showValidationMessage: false,
          isPrimary: false,
        },
      ]);

      const addContact = () => {
        contactList.value.push({
          phone: '',
          showValidationMessage: false,
          isPrimary: false,
        });
      };
      const phone = ref('');

      const isInternationalPhoneValid = ref<any>([]);
      const validateNumbers = (t, i) => {
        console.log(t, i);
        isInternationalPhoneValid.value[i] = t.valid;
      };

      return {
        onSubmitCreate,
        onSubmitCreateGuardian,
        addNewRow,
        guardians,
        customerId,
        deleteCustomer,
        removeContact,
        addContact,
        selectedCustomers,
        selectedCategory,
        selectedLevel,
        selectedStatuses,
        selectedGender,
        selectedDayes,
        selectedNationality,
        documents,
        statuses,
        attachmentSchema,
        guardianValidation,
        genders,
        nationalities,
        selectedItem,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        classes,
        customers,
        horses,
        onSubmitCreateAttachment,
        shipmentData: [],
        selectedDoc,
        contactList,
        preferredDayes,
        tableHeader,
        tableData,
        dynamicGuardiansSchema,
        calculateAge,
        ageInYear,
        dateOfBirth,
        horseOwner,
        freeRegistration,
        phone,
        validateNumbers,
        isInternationalPhoneValid,
        categoryList,
        classLevelList,
        matchedClassLevelList,
        primaryContactIndex,
      };
    },
  });
